import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  viewBox: "0 0 512 512"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "256",
      cy: "256",
      r: "256",
      style: {"fill":"#f0f0f0"}
    }, null, -1),
    _createElementVNode("path", {
      d: "M512 256c0-110.071-69.472-203.906-166.957-240.077v480.155C442.528 459.906 512 366.071 512 256",
      style: {"fill":"#d80027"}
    }, null, -1),
    _createElementVNode("path", {
      d: "M0 256c0 110.071 69.472 203.906 166.957 240.077V15.923C69.472 52.094 0 145.929 0 256",
      style: {"fill":"#6da544"}
    }, null, -1)
  ])))
}
export default { render: render }